import router from '@/router';
<template>
	<v-layout row wrap justify-center class="pa-4">
	  <v-flex xs12 sm8 md5 lg4>

	   	<v-card>
        <v-flex xs12>
          <v-toolbar color="primary" dark>
            <v-toolbar-title > Recuperar contraseña </v-toolbar-title>
           	<v-spacer></v-spacer>
              
          </v-toolbar>
        </v-flex>
      </v-card>

      <v-card class="mt-1 pa-3" align="center">
        <v-img
              :src="require('../assets/Maifon.jpeg')"
              class="my-3"
              contain
              height="150"
            ></v-img>
            <!-- EMAIL -->
        <v-flex xs12>
          <v-text-field
            solo
            autofocus
            v-model="email" 
            append-icon="mdi-email" 
            label="Correo" 
            required
            type="email">
          </v-text-field>
        </v-flex>
        
        <v-divider></v-divider>
				
				<v-card-actions class="mt-1 mx-3">
            <v-spacer></v-spacer>
            <v-btn dark text color="secondary" :to="{name:'Login'}">Cancelar</v-btn>
            <v-btn color="primary darken-1" dark @click="validarCorreo" class="elevation-5">Recuperar</v-btn>
             </v-card-actions>
      </v-card>
	  </v-flex>
	</v-layout>
</template>

<script>
  export default {
    data(){
      return{
        email:'',
      }
    },
    created(){
      this.$emit('muestra', true)
    },
    methods:{
        validarCorreo(){

            const body = {
                email: this.email,
                opcion: 5
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            async function getUsr(){
                const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php',params)
                const data = await response.json()
                return data
            }
            
            var arreglo = new Uint32Array(1)
            var arreglo2 = []
            window.crypto.getRandomValues(arreglo)
            arreglo[0].toString().split('').forEach((value, index)=>{
                if (index < 6) {
                    arreglo2.push(value)
                }
            })
            const codigo = arreglo2.join('')

            const body2 = {
                email: this.email,
                codigo: codigo,
                opcion: 17
            }
            const params2 = {
                body: JSON.stringify(body2),
                method: 'post'
            }
            async function insertCode(){
                const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php',params2)
                const data = await response.json()
                return data
            }

            const body3 = {
                email: this.email,
                codigo: codigo
            }
            const params3 = {
                body: JSON.stringify(body3),
                method: 'post'
            }
            async function sendEmail(){
                const response = await fetch('https://maifon.mx/Conexion/enviarCorreo.php', params3)
                const data = await response
                return data
            }

            getUsr().then(d=>{
                if (d.length!==0) {
                    insertCode().then(d=>{
                      sendEmail().then(r=>{
                        this.$router.push({ name: 'VerificaCodigo', params:{ email: this.email }})
                      }).catch(er=>{
                        alert('Hubo un error al enviar el correo')
                      })
                    }).catch(e=>{
                      alert('Hubo un error al crear el codigo de verificacion')
                    })
                }else{
                    alert('El correo no existe')
                }
            })
            return 0
        }
    }
  };


</script>

<style scoped>
  
  .logo{
    align-content: "center";
    text-align: center;

  }
</style>
